<template>
    <modal ref="modalPedido" titulo="" :no-aceptar="true" no-cancelar :btns="buttons" @accion="accionesBottones">
        <ValidationObserver ref="form1">
            <div class="row justify-center mx-0 my-2">
                <div class="col pl-4 text-general f-18">
                    Pedido: ID. {{ pedido.id }}
                </div>
                <div class="col text-general text-center f-18">
                    {{ $config.vendedor }}: {{ pedido.nombre_tienda }}
                </div>
            </div>
            <div class="row mx-0 justify-center my-4">
                <p class="col text-gr-general f-600">
                    <i class="f-25 mr-3" :class="iconosPedidos(pedido.estado)" />
                    {{ estado_pedido(pedido.estado) }}
                </p>
                <div class="col">
                    <div class="bg-general3 text-white text-center cr-pointer br-12 px-2 py-2 shadow" @click="irVerPedido">
                        Ver Detalle del pedido
                    </div>
                </div>
            </div>
            <div class="row mx-0 justify-center">
                <ValidationProvider v-slot="{errors}" rules="required" name="Fecha" tag="div" class="col-5">
                    <label class="text-general f-400 pl-4 f-15"> Fecha de Entrega </label>
                    <el-date-picker
                    v-model="model.entrega_fecha"
                    type="date"
                    size="small"
                    placeholder="Fecha"
                    class="w-100"
                    value-format="yyyy-MM-dd"
                    value="yyyy-MM-dd"
                    :clearable="false"
                    :picker-options="pickerOptions"
                    :disabled="!editar_entrega"
                    @change="set_horario"
                    />
                    <p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
                </ValidationProvider>

                <ValidationProvider v-slot="{errors}" rules="required" name="Horario" tag="div" class="col-5 pl-0">
                    <label class="text-general f-400 pl-4 f-15"> Hora de Entrega </label>
                    <el-select v-model="model.entrega_horario" size="small" class="w-100" placeholder="Horario" :disabled="!editar_entrega">
                        <el-option
                        v-for="item in horarios"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                        :disabled="item.disabled"
                        />
                    </el-select>
                    <p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
                </ValidationProvider>

                <div class="col-auto d-flex align-items-end">
                    <el-tooltip placement="bottom" content="Editar Entrega" effect="light">
                        <i v-if="!editar_entrega" class="icon-pencil-outline f-22 hover-icons cr-pointer" @click="modoEditar" />
                    </el-tooltip>
                    <div v-if="editar_entrega" class="entrega-editada px-2 py-2 cr-pointer" @click="modoGuardar">
                        <i class="icon-ok-circled-outline" />
                    </div>
                </div>
            </div>
        </ValidationObserver>
        <div v-for="producto in productos" :key="producto.id" class="row mx-0 mt-3">
            <div class="col-auto px-0">
                <i v-if="producto.estado_icono" class="f-22" :class="iconos(producto.estado_icono)" />
                <i v-else class="icon-sticker-emoji f-22" />
            </div>
            <div class="col rompe-palabras text-general d-middle">
                {{ _.defaultTo(producto.nombre, 'Producto desconocido') }}
            </div>
            <div class="col d-middle-center rompe-palabras">
                {{ producto.presentacion }}
            </div>
            <div class="col-auto pr-0 d-middle-center rompe-palabras">
                <span class="f-600"> {{ producto.cantidad_tipo != 2 ? Math.round(producto.cantidad) : producto.cantidad }} </span> {{ producto.unidad }}
            </div>
        </div>
    </modal>
</template>

<script>
import PedidosMapa from '~/services/almacen/pedidos_mapa'
import Pedidos from '~/services/pedidos/pedidos-admin'
import {mapGetters} from 'vuex'
import moment from 'moment'


export default {
    data(){
        return {
            id_admin_pedido: null,
            pedido:{
                id: null,
                estado: 0,
                nombre_tienda: '',
                id_cedis_tienda: 0
            },
            model:{
                id: null,
                entrega_fecha: null,
                entrega_horario: null
            },
            productos:[],
            horarios:[],
            pickerOptions: {
                disabledDate(time){
                    return time.getTime() < moment().subtract(1,'days');
                }
            },
            editar_entrega: false,
            buttons: [
                { texto: 'Cerrar', color: 'bg-light-f5 text-general2 br-12', accion: 1}
            ]
        }
    },
    computed:{
        ...mapGetters({
            id_cedis:'cedis/id_cedis',
        }),
    },
    watch: {
        productos(newValue){
            console.log(newValue);
        }
    },
    methods: {
        async toggle(pedido){
            console.log(pedido);
            this.id_admin_pedido = pedido.id
            await this.detallePedido()
            this.set_franja()
            this.$refs.modalPedido.toggle();
        },
        modoEditar(){
            this.editar_entrega = !this.editar_entrega
        },
        async modoGuardar(){
            try {
                if(this.model.entrega_fecha !== this.pedido.entrega_fecha || this.model.entrega_horario !== this.pedido.entrega_horario){
                    const {data} = await PedidosMapa.entrega_fecha(this.model)
                    this.$emit('actualizar', data.adminPedido)
                    this.notificacion('Mensaje', 'Pedido actualizado', 'success')
                }
                this.editar_entrega = !this.editar_entrega
            } catch (e){
                this.error_catch(e)
            }
        },
        async detallePedido(){
            try {
                const {data} = await PedidosMapa.detalle_pedido_mapa({id_admin_pedido: this.id_admin_pedido})
                this.pedido = data.adminPedido
                this.model.id = this.pedido.id
                this.model.entrega_fecha = this.pedido.entrega_fecha
                this.model.entrega_horario = this.pedido.entrega_horario
                this.productos = data.productos
            } catch (e){
                this.error_catch(e)
            }

        },
        async set_franja(){
            try {
                let id_cedis = this.pedido.id_cedis_tienda
                if(id_cedis === null){
                    this.notificacion('Advertencia','La tienda no tiene un CEDIS asignado.','warning')
                    return
                }
                const {data} = await Pedidos.get_horarios(id_cedis)
                data.horarios.map(h=>{
                    h.disabled = false
                })
                this.horarios = data.horarios

            } catch (e){
                this.error_catch(e)
            }
        },
        set_horario(fecha){
            let hoy = moment().format('YYYY-MM-DD')
            let hora = moment().hour()

            if(hoy === fecha){
                this.horarios.map(h=>{
                    if(h.fin < hora){
                        h.disabled=true
                    }
                })

            }else{
                this.horarios.map(h=>{
                    h.disabled=false
                })
            }

            this.model.entrega_horario = ''
        },
        iconos(estado){
            switch(estado){
            case 0:
                return 'icon-sticker-emoji text-muted';
            case 1:
                return 'icon-ok-circled-outline color-IcoImagen';
            case 2:
                return 'icon-cancel-circled-outline text-red';
            case 3:
                return 'icon-search text-gr-general';
            case 4:
                return 'icon-currency-usd-circle text-usd';
            case 5:
                return 'icon-attention-outline text-gr-red';
            default:
                return 'icon-sticker-emoji text-muted';
            }
        },
        iconosPedidos(estado){
            switch(estado){
            case 1:
                return 'icon-receipt';
            case 2:
                return 'icon-ok-circled';
            case 201:
                return 'icon-cart-arrow-down';
            case 202:
                return 'icon-shopping ';
            case 3:
                return 'icon-truck-fast-outline';
            }
        },
        estado_pedido(estado){
            switch (estado){
            case 1:
                return 'Sin confirmar'
            case 2:
                return 'Confirmado'
            case 3:
                return 'Enviado'
            case 32:
            case 33:
            case 34:
            case 4:
                return 'Entregado'
            case 21:
            case 22:
            case 24:
            case 26:
                return 'Cancelado'
            case 23:
            case 27:
                return 'Cancelado'
            case 25:
                return 'Rechazado'
            case 201:
                return 'Alistado'
            case 202:
                return 'Empacado'
            default:
                return ''
            }
        },
        irVerPedido(){
            console.log(this.$usuario);
            this.$refs.modalPedido.toggle();
            this.$router.push({name: `${this.$usuario.rol_nombre}.almacen.pedidos`, params: { id_pedido: this.id_admin_pedido}})
        },
        accionesBottones(accion){
            if(accion == 1){
                this.$refs.modalPedido.toggle();
            }
        },
    }
}
</script>

<style lang="scss">
.br-12{
    border-radius: 12px;
}
.text-red{
    color: #FF2525;
}
.text-usd{
    color: #03D6BC;
}
.entrega-editada{
    background-color: var(--color-light);
    color: var(--color-general2);
    border-radius: 12px;
    box-shadow: 0px 3px 6px #00000029;
}
</style>
